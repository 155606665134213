import { FC } from 'react';

import MuiTextField from '@mui/material/TextField';

import { Autocomplete } from 'src/components/formik-material-ui/Autocomplete';

interface UserNode {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export const CompanyUserSelect: FC = (props: any) => {
    const { required, users } = props;

    return (
        <Autocomplete
            {...props}
            isOptionEqualToValue={(option: UserNode, value?: UserNode) => option?.id === value?.id}
            getOptionLabel={(option: UserNode): string =>
                option.firstName || option.lastName
                    ? `${option.firstName} ${option.lastName} (${option.email})`
                    : option.email
            }
            options={users}
            renderInput={(params: any): JSX.Element => (
                <MuiTextField
                    fullWidth
                    variant="outlined"
                    name={props.field.name}
                    {...params}
                    error={!!props.form.errors[props.field.name]}
                    helperText={
                        props.form.touched[props.field.name] && props.form.errors[props.field.name]
                    }
                    label={props.label || 'User'}
                    required={!!required}
                />
            )}
        />
    );
};
