import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { AgGridColumn } from 'ag-grid-react';
import { FC, useMemo, useState } from 'react';

import { useLingui } from '@lingui/react';
import AddIcon from '@mui/icons-material/Add';
import {
    Box,
    Button,
    Card,
    CardHeader,
    Divider,
    Drawer,
    Typography,
    useTheme,
} from '@mui/material';

import { AgGrid } from 'src/components/AgGrid';
import { AddOrEditUserForm } from 'src/components/users/AddOrEditUserForm';
import { InviteUserForm } from 'src/components/users/InviteUserForm';
import ActionsRenderer from 'src/components/users/tableComponents/ActionsRenderer';
import { NameRenderer } from 'src/components/users/tableComponents/NameRenderer';
import RoleRenderer from 'src/components/users/tableComponents/RoleRenderer';
import { TooltipRenderer } from 'src/components/users/tableComponents/TooltipRenderer';
import 'src/styles/organization/agGrid.css';
import useWindowSize from 'src/utils/useWindowSize';
import { UserManagementModal } from 'src/videoModals';

interface Props {
    users: any[];
    unassignedUsers: any[];
    tableHeight?: number;
    actionsAllowed?: boolean;
    inviteNewUserAction: any;
    addOrEditExistingUserAction: any;
    removeUserAction: any;
    afterSuccessAction?: any;
}

export const UserManagmentTable: FC<Props> = ({
    users,
    unassignedUsers,
    tableHeight,
    actionsAllowed,
    inviteNewUserAction,
    addOrEditExistingUserAction,
    removeUserAction,
    afterSuccessAction,
}: Props) => {
    const { i18n } = useLingui();

    const windowSize = useWindowSize();

    const [, setGridApi] = useState(null);
    const [, setGridColumnApi] = useState(null);

    const columnData = ['email', 'firstName', 'lastName', 'role', 'actions'];

    const [invite, setInvite] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    const theme = useTheme();
    const isSmallScreen = windowSize.width < theme.breakpoints.values.sm;

    const minWidths = {
        email: 260,
        firstName: 130,
        lastName: 130,
        role: 180,
        actions: 260,
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    const handleInvite = () => {
        setInvite(true);
        setDrawerOpen(true);
    };

    const handleAdd = () => {
        setCurrentUser(null);
        setInvite(false);
        setDrawerOpen(true);
    };

    const handleEdit = (user) => {
        setCurrentUser(user);
        setInvite(false);
        setDrawerOpen(true);
    };

    const removeUser = (userId) => {
        removeUserAction(userId, { afterSuccessAction });
    };

    const onGridReady = async (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.columnApi.getColumn('email').setSort('asc');
        params.api.onFilterChanged();
    };

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    };

    const onGridSizeChanged = (params) => {
        params.api.sizeColumnsToFit();
    };

    const getCellRenderer = (col) => {
        if (col === 'email') {
            return 'tooltipRenderer';
        }
        if (col === 'firstName') {
            return 'nameRenderer';
        }
        if (col === 'lastName') {
            return 'nameRenderer';
        }
        if (col === 'role') {
            return 'roleRenderer';
        }
        if (col === 'actions') {
            return 'actionsRenderer';
        }
        return null;
    };

    const rowData = users.map((user) => ({
        ...user,
        actions: {
            handleEdit,
            removeUser,
        },
    }));

    const titleComponent = useMemo(
        () => (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: '11px',
                    }}
                >
                    {i18n._('User management')}
                </Typography>
                <UserManagementModal startTime={67} />
            </Box>
        ),
        [],
    );

    function getHeaderName(col: string) {
        if (col === 'email') {
            return i18n._('Email');
        }
        if (col === 'firstName') {
            return i18n._('First Name');
        }
        if (col === 'lastName') {
            return i18n._('Last Name');
        }
        if (col === 'role') {
            return i18n._('Role');
        }
        if (col === 'actions') {
            return i18n._('Actions');
        }
        if (col === 'admin') {
            return i18n._('Admin');
        }
        if (col === 'user') {
            return i18n._('User');
        }
        console.log('Missing translation for column', col);
        return '';
    }

    return (
        <>
            <Card variant="outlined">
                <CardHeader
                    title={titleComponent}
                    action={
                        actionsAllowed &&
                        !isSmallScreen && (
                            <Box>
                                <Button
                                    sx={{ mr: 1 }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={handleAdd}
                                    data-testid="addButton"
                                >
                                    <AddIcon fontSize="small" sx={{ mr: 1 }} />
                                    {i18n._('Add existing user')}
                                </Button>
                                <Button
                                    sx={{ mr: 1 }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={handleInvite}
                                    data-testid="inviteButton"
                                >
                                    <AddIcon fontSize="small" sx={{ mr: 1 }} />
                                    {i18n._('Invite new user')}
                                </Button>
                            </Box>
                        )
                    }
                />
                {isSmallScreen && (
                    <Box sx={{ pl: 2, pb: 2 }}>
                        <Button
                            sx={{ mr: 1, mb: 1 }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleAdd}
                            data-testid="addButton"
                        >
                            <AddIcon fontSize="small" sx={{ mr: 1 }} />
                            {i18n._('Add existing user')}
                        </Button>
                        <Button
                            sx={{ mr: 1, mb: 1 }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleInvite}
                            data-testid="inviteButton"
                        >
                            <AddIcon fontSize="small" sx={{ mr: 1 }} />
                            {i18n._('Invite new user')}
                        </Button>
                    </Box>
                )}
                <Divider />
                <div style={{ width: '100%' }}>
                    <div id="grid-wrapper" style={{ width: '100%' }}>
                        <div
                            id="grid-class-wrapper"
                            className="ag-theme-material"
                            style={{ height: `${tableHeight || 700}px`, width: '100%' }}
                        >
                            <AgGrid
                                reactUi={!true}
                                onGridReady={onGridReady}
                                onFirstDataRendered={onFirstDataRendered}
                                onGridSizeChanged={onGridSizeChanged}
                                rowData={rowData}
                                defaultColDef={{
                                    initialWidth: 200,
                                    sortable: true,
                                    resizable: true,
                                    floatingFilter: true,
                                    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
                                }}
                                components={{
                                    tooltipRenderer: TooltipRenderer,
                                    roleRenderer: RoleRenderer,
                                    actionsRenderer: ActionsRenderer,
                                    nameRenderer: NameRenderer,
                                }}
                            >
                                {columnData.map((col) => (
                                    <AgGridColumn
                                        key={col}
                                        field={col}
                                        filter={col !== 'actions' && 'agMultiColumnFilter'}
                                        headerName={getHeaderName(col)}
                                        cellRenderer={getCellRenderer(col)}
                                        cellStyle={{
                                            textAlign: col === 'email' ? 'left' : 'center',
                                        }}
                                        minWidth={minWidths[col]}
                                        hide={col === 'actions' && !actionsAllowed}
                                    />
                                ))}
                            </AgGrid>
                        </div>
                    </div>
                </div>
            </Card>
            <Drawer
                anchor="right"
                open={drawerOpen}
                style={{ zIndex: 1300 }}
                PaperProps={{
                    sx: {
                        p: 2,
                        width: windowSize.width >= 520 ? 520 : '100%',
                    },
                }}
            >
                {invite ? (
                    <InviteUserForm
                        inviteNewUserAction={inviteNewUserAction}
                        closeDrawer={closeDrawer}
                        afterSuccessAction={afterSuccessAction}
                    />
                ) : (
                    <AddOrEditUserForm
                        addOrEditExistingUserAction={addOrEditExistingUserAction}
                        closeDrawer={closeDrawer}
                        afterSuccessAction={afterSuccessAction}
                        users={unassignedUsers}
                        userToEdit={currentUser}
                    />
                )}
            </Drawer>
        </>
    );
};
