/**
 * @generated SignedSource<<19e37246b72ad301d8fd798583b56309>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuoteProviderQuery$variables = {
  quoteId: string;
};
export type QuoteProviderQuery$data = {
  readonly sourceQuote: {
    readonly announcementSetId: string | null;
    readonly companyName: string | null;
    readonly id: string;
    readonly integerId: number | null;
    readonly internalDocumentSetId: string | null;
    readonly projectName: string | null;
    readonly proposals: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly company: {
            readonly companyName: string;
            readonly id: string;
            readonly logo: string | null;
          };
          readonly id: string;
          readonly isActive: boolean;
          readonly status: string | null;
        } | null;
      } | null>;
    };
    readonly status: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"Questions_sourceQuote" | "QuoteUserManagementComponent_sourceQuote">;
  } | null;
};
export type QuoteProviderQuery = {
  response: QuoteProviderQuery$data;
  variables: QuoteProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "quoteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "quoteId",
    "variableName": "quoteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "integerId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "announcementSetId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalDocumentSetId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "QuoteProposalNodeConnection",
  "kind": "LinkedField",
  "name": "proposals",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuoteProposalNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuoteProposalNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isActive",
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyNode",
              "kind": "LinkedField",
              "name": "company",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "logo",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuoteProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QuoteNode",
        "kind": "LinkedField",
        "name": "sourceQuote",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuoteUserManagementComponent_sourceQuote"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Questions_sourceQuote"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuoteProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QuoteNode",
        "kind": "LinkedField",
        "name": "sourceQuote",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SourceUserRoleNode",
            "kind": "LinkedField",
            "name": "userRoles",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "questionSetId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb84856147dd03e1364619608b8aa152",
    "id": null,
    "metadata": {},
    "name": "QuoteProviderQuery",
    "operationKind": "query",
    "text": "query QuoteProviderQuery(\n  $quoteId: ID!\n) {\n  sourceQuote(quoteId: $quoteId) {\n    id\n    integerId\n    announcementSetId\n    internalDocumentSetId\n    status\n    projectName\n    companyName\n    proposals {\n      edges {\n        node {\n          id\n          isActive\n          status\n          company {\n            id\n            companyName\n            logo\n          }\n        }\n      }\n    }\n    ...QuoteUserManagementComponent_sourceQuote\n    ...Questions_sourceQuote\n  }\n}\n\nfragment Questions_sourceQuote on QuoteNode {\n  id\n  integerId\n  questionSetId\n  status\n  proposals {\n    edges {\n      node {\n        id\n        isActive\n        company {\n          id\n          companyName\n          logo\n        }\n      }\n    }\n  }\n}\n\nfragment QuoteUserManagementComponent_sourceQuote on QuoteNode {\n  id\n  userRoles {\n    user {\n      id\n      firstName\n      lastName\n    }\n    email\n    role\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8f2517a28f65746a2a92b922673cad5";

export default node;
