/**
 * @generated SignedSource<<93105f4ea0605c4c5f47ab8c2e243ae8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateUserWithQuotePermissionInput = {
  clientMutationId?: string | null;
  email: string;
  firstName: string;
  lastName: string;
  quoteId: string;
  role: string;
};
export type QuoteUserManagementComponentCreateUserMutation$variables = {
  input: CreateUserWithQuotePermissionInput;
};
export type QuoteUserManagementComponentCreateUserMutation$data = {
  readonly createUserWithQuotePermission: {
    readonly quote: {
      readonly companyName: string | null;
      readonly id: string;
      readonly projectName: string | null;
      readonly userRoles: ReadonlyArray<{
        readonly email: string | null;
        readonly role: string | null;
        readonly user: {
          readonly firstName: string;
          readonly id: string;
          readonly lastName: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type QuoteUserManagementComponentCreateUserMutation = {
  response: QuoteUserManagementComponentCreateUserMutation$data;
  variables: QuoteUserManagementComponentCreateUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUserWithQuotePermissionPayload",
    "kind": "LinkedField",
    "name": "createUserWithQuotePermission",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QuoteNode",
        "kind": "LinkedField",
        "name": "quote",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SourceUserRoleNode",
            "kind": "LinkedField",
            "name": "userRoles",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuoteUserManagementComponentCreateUserMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuoteUserManagementComponentCreateUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "562a1ff935fb230140b735c8bb7c4969",
    "id": null,
    "metadata": {},
    "name": "QuoteUserManagementComponentCreateUserMutation",
    "operationKind": "mutation",
    "text": "mutation QuoteUserManagementComponentCreateUserMutation(\n  $input: CreateUserWithQuotePermissionInput!\n) {\n  createUserWithQuotePermission(input: $input) {\n    quote {\n      id\n      projectName\n      companyName\n      userRoles {\n        user {\n          id\n          firstName\n          lastName\n        }\n        email\n        role\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b061ec810c0267840d97570b729bcc2e";

export default node;
